import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { Helmet } from "react-helmet"
import ogImage from "../../static/img/og-image.jpg"
import "../styles/global.scss"

const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const url =
  "//dinostream.us2.list-manage.com/subscribe/post?u=a0028c5375e8658ead5928167&id=b4c315bff8"

export default () => {
  const [email, setEmail] = useState("")
  const [viewers, setViewers] = useState("100")
  const [minutes, setMinutes] = useState("0")
  const [hours, setHours] = useState("1")
  const [ticket, setTicket] = useState("5")
  const [cost, setCost] = useState(60)

  useEffect(() => {
    // 5 resolutions
    const base = 5 * 2
    const totalMinutes = ~~hours * 60 + ~~minutes
    const bandwidthPrice = ~~viewers > 10000 ? 0.5 : 0.7
    const bandwidthPerMinute = (~~viewers * bandwidthPrice) / 60
    const totalCost = base + totalMinutes * bandwidthPerMinute
    setCost(Math.round(totalCost))
  }, [viewers, minutes, hours, cost])

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:image" content={ogImage} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>Affordable & Scalable Pay-Per-View Video Monetization</title>
      </Helmet>
      <section className="hero-section container-fluid text-white mx-auto text-center">
        <div className="row">
          <div className="col-12 mb-1">
            <h1 className="display-4">Monetize your online video</h1>
            <p className="lead">
              Affordable and scalable video streaming with pay-per-view, powered
              by Amazon Web Services
            </p>
          </div>
        </div>

        <div className="container mt-5 px-0">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-10">
              <p>
                Subscribe for our mailing list for latest news and be among the
                first to test the platform.
                <br />
                Expected lunch date before April 2021.
              </p>
              <div className="row justify-content-center">
                <div className="col-12 col-sm-8 col-lg-5">
                  <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                      <>
                        <div className="input-group mb-3">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            aria-label="Email"
                            value={email}
                            disabled={
                              status === "success" || status === "sending"
                            }
                            onChange={e => {
                              setEmail(e.target.value)
                            }}
                            aria-describedby="button-subscribe"
                          />
                          <div className="input-group-append">
                            <button
                              disabled={
                                !email ||
                                !email.includes("@") ||
                                status === "success" ||
                                status === "sending"
                              }
                              className="btn btn-primary"
                              type="button"
                              onClick={() => subscribe({ EMAIL: email })}
                              id="button-subscribe"
                            >
                              <img src="/img/send.svg" alt="Subscribe" />
                            </button>
                          </div>
                        </div>
                        {status === "sending" && (
                          <div className="text-white">sending...</div>
                        )}
                        {status === "error" && (
                          <div
                            className="text-white"
                            dangerouslySetInnerHTML={{ __html: message }}
                          />
                        )}
                        {status === "success" && (
                          <div className="text-white">
                            Thank you for subscribing!
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-4 mb-md-0">
              <div className="screen-img">
                <img
                  src="/img/hero.png"
                  alt="Workflow"
                  className="d-none d-md-inline-block w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid py-7 spikes">
        <div className="container px-0">
          <div className="row text-center justify-content-center">
            <div className="col-12 mb-5">
              <h1>Solutions</h1>
              <p className="lead">
                AWS video services wrapped up in an user-friendly platform
              </p>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 mb-5">
              <div className="d-flex align-items-center justify-content-center mb-3">
                <img src="/img/check.svg" className="mr-1" alt="Check" />
                <h5 className="mb-0">Affordable streaming</h5>
              </div>
              <p>
                All streaming services run directly on AWS. The cost for
                bandwidth and storage used is charged on a pay-per-use basis.
                AWS is one of the most affordable streaming options.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 mb-5">
              <div className="d-flex align-items-center justify-content-center mb-3">
                <img src="/img/check.svg" className="mr-1" alt="Check" />
                <h5 className="mb-0">Scalable streaming</h5>
              </div>
              <p>
                Our platform gives you direct access to AWS Cloud
                Infrastructure. It is the most secure, extensive, and reliable
                cloud platform, offering over 175 data centers globally.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 mb-5">
              <div className="d-flex align-items-center justify-content-center mb-3">
                <img src="/img/check.svg" className="mr-1" alt="Check" />
                <h5 className="mb-0">Keep all PPV profit</h5>
              </div>
              <p>
                Our platform is AWS reseller for end users. All money from
                ticket sales are processed by your payment gateway provider and
                go to your pocket.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-lg-8 mb-5">
              <div className="d-flex align-items-center justify-content-center mb-3">
                <img src="/img/check.svg" className="mr-1" alt="Check" />
                <h5 className="mb-0">Easy-to-use platform</h5>
              </div>
              <p>
                We have 10+ years of experience in creating complex web
                solutions. Our goal is to deliver AWS streaming to the end user
                in a simple and reliable way.
              </p>
            </div>
          </div>

          <div
            style={{ borderRadius: 10 }}
            className="row text-center justify-content-center bg-light pt-5"
          >
            <div className="col-12 col-md-8 mb-5">
              <h1>Cost calculator</h1>
              <p>
                This is a rough, worst-case cost estimate for a single live
                stream event. This estimation includes resolutions 1080p, 720p,
                480p, 360p, 160p and worldwide stream distribution.
              </p>

              <div className="form-group">
                <label htmlFor="formControlRange">Viewers: {viewers}</label>
                <input
                  type="range"
                  min="100"
                  value={viewers}
                  onChange={e => setViewers(e.target.value)}
                  max="100000"
                  className="form-control-range"
                  id="formControlRange"
                />
              </div>
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label htmlFor="hours">Hours</label>
                    <input
                      type="number"
                      onChange={e => setHours(e.target.value)}
                      className="form-control"
                      value={hours}
                      id="hours"
                      aria-describedby="hours"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label htmlFor="minutes">Minutes</label>
                    <input
                      type="number"
                      onChange={e =>
                        setMinutes(
                          parseInt(e.target.value, 10) > 60
                            ? "60"
                            : e.target.value
                        )
                      }
                      value={minutes}
                      min="0"
                      max="60"
                      className="form-control"
                      id="minutes"
                      aria-describedby="minutes"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <p className="mb-2">Regions</p>
                  <div className="form-check mt-md-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="regionCheck"
                      value="option1"
                      checked={true}
                      disabled={true}
                    />
                    <label className="form-check-label" htmlFor="regionCheck">
                      Worldwide
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-3 mt-3 mt-md-0">
                  <div className="form-group">
                    <label htmlFor="ticket">Ticket price</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          $
                        </span>
                      </div>
                      <input
                        type="number"
                        onChange={e => setTicket(e.target.value)}
                        className="form-control"
                        value={ticket}
                        id="ticket"
                        aria-describedby="ticket"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                <p className={"lead mt-4 my-md-3"}>
                  Estimated cost: ${numberWithCommas(cost)}
                </p>
                <p className={"lead my-md-3"}>-</p>
                <p className={"lead my-md-3"}>
                  Ticket sales: ${numberWithCommas(~~ticket * viewers)}
                </p>
                <p className={"lead my-md-3"}>=</p>
                <p className={"lead mb-4 my-md-3"}>
                  Profit: ${numberWithCommas(~~ticket * viewers - cost)}
                </p>
              </div>

              <p className="small">
                This is not a final price. Your account will be charged on
                pay-per-use basis, so it is likely that the final cost will be
                lower. As we scale our platform we can get better deals from
                Amazon and we can offer better prices.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid bg-light pb-5 pt-8">
        <div className="container px-0">
          <div className="row text-center justify-content-center">
            <div className="col-12 mb-5">
              <h1>How it works</h1>
              <p className="lead">Learn more about the platform workflow</p>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-5">
              <div className="home-number h5 mb-2">1</div>
              <h5 className="mb-3">Create an account</h5>
              <p>
                Singing in is all you need to test the platform. Get familiar
                with the dashboard and create a trial live stream for free.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-5">
              <div className="home-number h5 mb-2">2</div>
              <h5 className="mb-3">Setup a stream</h5>
              <p>
                Create a live or video on-demand stream with a single button
                click. Manage your streams in an interactive dashboard.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-5">
              <div className="home-number h5 mb-2">3</div>
              <h5 className="mb-3">Setup a video player</h5>
              <p>
                Customize the look and feel of the video player, assign a stream
                to it, copy a generated embed code and paste it on your website.
              </p>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 mb-5">
              <div className="home-number h5 mb-2">4</div>
              <h5 className="mb-3">Setup pay-per-view</h5>
              <p>
                Link a payment gateway (Stripe, and more), setup ticket price
                and restrict access for a stream.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid py-7 bg-primary">
        <div className="row text-center text-white">
          <div className="col-12">
            <h4 className="mb-0">Stay tuned for more news coming soon!</h4>
            <p className="lead">
              Reach us at{" "}
              <a className="text-white" href="mailto:info@dinostream.com">
                info@dinostream.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
