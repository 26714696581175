import React from "react"
import { Link } from "gatsby"

export default ({ children }) => (
  <main>
    <header className="position-absolute w-100 d-flex flex-column flex-md-row align-items-center justify-content-center my-5">
      <Link to="/" className="my-0 _mr-md-auto font-weight-normal d-block" style={{width: 180}}>
        <img
          src="/img/logo.svg"
          alt="Dinostream Logo"
          className="img-fluid"
        />
      </Link>
     {/* <nav className="my-2 my-md-0 mr-md-3">
        <Link className="p-2 text-dark" to="/contact/">
          Features
        </Link>
        <Link className="p-2 text-dark" to="/contact/">
          Enterprise
        </Link>
        <Link className="p-2 text-dark" to="/contact/">
          Support
        </Link>
        <Link className="p-2 text-dark" to="/contact/">
          Pricing
        </Link>
      </nav>
      <Link className="btn btn-outline-primary" to="/contact/">
        Sign up
      </Link>*/}
    </header>
    {children}
  </main>
)
